<template>
  <div class="py-[30px] max-w-[1440px] mx-auto" id="service">
    <div
      class="flex flex-col justify-center items-center max-w-[1024px] mx-auto text-center"
    >
      <p
        data-reveal="left"
        v-scroll-reveal
        class="h2 pb-8 relative after:content-[''] after:absolute after:h-[2px] after:w-[80%] after:bg-primary after:bottom-[16px] after:left-[10%] after:rounded-full text-black"
      >
        Our Services
      </p>
      <p
        class="md:text-[20px] sm:text-[18px] text-[16px] md:py-[2.5rem] py-[1rem] text-balance text-black"
        data-reveal="right"
        v-scroll-reveal
      >
        <span class="text-primary font-bold">Detech Solution</span> delivers tailored IT solutions that enhance efficiency, productivity, and growth. Our expert team provides reliable, high-quality services, empowering businesses in the digital age. Explore our key offerings:
      </p>
    </div>

    <div class="lg:pt-[40px] pt-[20px]">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div
          v-for="(service, index) in servicesData"
          :key="index"
          data-reveal
          v-scroll-reveal
          class="p-4 px-5 bg-white rounded-lg [box-shadow:rgba(14,_63,_126,_0.06)_0px_0px_0px_1px,_rgba(42,_51,_70,_0.03)_0px_1px_1px_-0.5px,_rgba(42,_51,_70,_0.04)_0px_2px_2px_-1px,_rgba(42,_51,_70,_0.04)_0px_3px_3px_-1.5px,_rgba(42,_51,_70,_0.03)_0px_5px_5px_-2.5px,_rgba(42,_51,_70,_0.03)_0px_10px_10px_-5px,_rgba(42,_51,_70,_0.03)_0px_24px_24px_-8px] hover:text-primary group duration-500 hover:cursor-default"
        >
          <div
            class="flex flex-col justify-center gap-2 items-center text-center h-full"
          >
            <div class="pointer-events-none">
              <div
                class="relative overflow-hidden rounded-md mb-4 max-w-[110px] h-[110px] w-[110px] mx-auto"
              >
                <video
                  :src="service.icon"
                  autoplay
                  loop
                  muted
                  class="object-contain h-full w-full pointer-events-none"
                ></video>
              </div>
              <h2 class="h4 py-4 group-hover:text-gray-900 text-black">
                {{ service.name }}
              </h2>
            </div>
            <p class="text-small text-muted">{{ service.description }}</p>
            <span
              class="h-[2px] w-[30%] bg-primary mt-4 group-hover:w-[calc(100%-16px)] duration-500"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import web_icon from "../assets/img/services/web-developer.webm";
import seo from "../assets/img/services/seo.webm";
import app from "../assets/img/services/mobile-apps.webm";
import software from "../assets/img/services/software.webm";
import ui_ux from "../assets/img/services/ui_ux.webm";
import web_scrapping from "../assets/img/services/web_scrap.webm";
import it_consultant from "../assets/img/services/it-consultant.webm";

interface Service {
  name: string;
  description: string;
  icon: string;
}

const servicesData: readonly Service[] = [
  {
    name: "Web Development",
    description:
      "Tailored websites designed for high performance, scalability, and exceptional user experiences.",
    icon: web_icon,
  },
  {
    name: "Search Engine Optimization",
    description:
      "Boost your online visibility and drive traffic with strategic SEO and technical enhancements.",
    icon: seo,
  },
  {
    name: "App Development",
    description:
      "Crafting seamless, high-performing mobile apps for iOS and Android platforms.",
    icon: app,
  },
  {
    name: "Software Development",
    description:
      "Custom software solutions built to address business challenges with reliability and scalability.",
    icon: software,
  },
  {
    name: "UI/UX Design",
    description:
      "Intuitive designs that combine aesthetics with functionality to deliver impactful user experiences.",
    icon: ui_ux,
  },
  {
    name: "IT Consulting",
    description:
      "Expert advice to optimize IT strategies, streamline operations, and accelerate digital transformation.",
    icon: it_consultant,
  },
  {
    name: "Web Scraping",
    description:
      "Automated solutions for efficient data extraction, enabling insights for research and decision-making.",
    icon: web_scrapping,
  },
];
</script>
