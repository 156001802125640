<template>
  <div class="text-center max-w-[992px] mx-auto px-[10px]">
    <h1
      data-reveal="left"
      v-scroll-reveal
      class="lg:text-[73px] md:text-[50px] sm:text-[32px] text-[28px] font-bold leading-[1.1] text-black"
    >
      Collaborative partners, not projects.
    </h1>
    <p
      data-reveal="right"
      v-scroll-reveal
      class="md:text-[20px] sm:text-[18px] text-[16px] md:py-[2.5rem] py-[1rem] md:max-w-[762px] max-w-[550px] mx-auto text-balance text-black"
    >
    We approach our clients' challenges as if they were our own. While our clients focus on driving their business, we take care of the technical needs that arise along the way.
    </p>
    <div class="wrapper" data-reveal="right" v-scroll-reveal>
      <a href="#contact" class="primary-btn px-3 py-3">CONTACT US</a>
    </div>
  </div>
</template>
