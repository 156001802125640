<template>
  <div class="max-w-[1440px] mx-auto px-[10px] lg:px-[20px]" id="project">
    <h2
      class="text-black text-center leading-[1] relative after:content-[''] after:absolute after:h-[2px] after:w-[75%] after:bottom-[16px] pb-8 after:bg-primary after:left-[12.5%] w-fit mx-auto my-3"
    >
      Project Showcase with Interactive Demos
    </h2>
    <div
      v-for="(site, index) in siteData"
      :key="site.name"
      class="grid lg:grid-cols-2 grid-cols-1 md:gap-16 gap-8"
      :class="{
        'odd-layout md:mt-12 md:mb-20 my-10': index % 2 === 0,
        'even-layout': index % 2 !== 0,
      }"
    >
      <div
        class="col-span-1 image-container"
        v-scroll-reveal
        data-reveal="left"
      >
        <img
          :src="site.image"
          loading="lazy"
          :alt="site.alt"
          class="w-full h-full max-h-[700px] object-top object-cover rounded-md outline outline-8 outline-[#e5edf5]"
        />
      </div>
      <div
        class="col-span-1 flex justify-center flex-col content-container"
        v-scroll-reveal
        data-reveal="right"
      >
        <p class="font-bold mt-4 text-muted">
          {{ site.name }}
        </p>
        <h2 class="h2 text-black">
          {{ site.title }}
        </h2>
        <p class="text-small text-muted md:py-2 max-[520px]:line-clamp-3 !leading-[1.3]">
          {{ site.description }}
        </p>
         <p v-show="site.description2" class="text-small text-muted md:py-2 max-[520px]:line-clamp-3 !leading-[1.3]">
          {{ site.description2 }}
        </p>
        <a
          :href="site.url"
          target="_blank"
          class="text-gray-950 font-bold flex items-center gap-2 w-fit"
        >
          <span class="text-primary">Visit site</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11 4H4V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V13"
              :stroke="site.color"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9 15L20 4"
              :stroke="site.color"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15 4H20V9"
              :stroke="site.color"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import nepvent from "../assets/img/showcase/nepvent_dashboard.webp";
import dami_experiance from "../assets/img/showcase/dami_experiance.webp";

type Color = `#${string}` | undefined;

interface Site {
  name: string;
  url: string;
  image: string;
  alt: string;
  title: string;
  description: string;
  description2?: string;
  color?: Color;
}

const siteData: readonly Site[] = [
  {
    name: "Nepvent",
    url: "https://nepvent.com",
    image: nepvent,
    alt: "nepvent",
    title: "Restaurant Management Like Clockwork",
    description:
      "Nepvent offers a comprehensive Restaurant Management System (RMS) designed to streamline restaurant operations. It provides features like order management, analytics, mobile ordering, and digital menus, all accessible via multiple devices without the need for proprietary hardware. With tools for tracking sales, inventory, and performance, Nepvent helps restaurants optimize efficiency, improve customer experience, and manage franchises remotely.",
    color: "#6731EC",
  },
  {
    name: "Dami Experience",
    url: "https://www.damiexperience.com/",
    image: dami_experiance,
    alt: "dami experience ",
    title: "Dami Experience - Website & Mobile App Development",
    description: "Dami Experience, a leading platform in Nepal, connects users with trusted service providers in various fields. We partnered with them to create an engaging and efficient digital solution comprising a website and a mobile app.",
    description2:"Our focus was to deliver a seamless user experience through modern design, intuitive navigation, and advanced features like personalized profiles and smart search filters. Built with scalability in mind, the platform is poised for future growth and expansion. Today, Dami Experience stands out as a reliable and user-friendly resource for discovering services in Nepal.",
    color: "#6731EC",
  },
];
</script>
<style scoped lang="scss">
.odd-layout .image-container {
  @media (min-width: 1024px) {
    @apply order-1;
  }
}

.odd-layout .content-container {
  @media (min-width: 1024px) {
    @apply order-2;
  }
}

.even-layout .image-container {
  @media (min-width: 1024px) {
    @apply order-2;
  }
}

.even-layout .content-container {
  @media (min-width: 1024px) {
    @apply order-1;
  }
}
</style>
