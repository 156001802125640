<template>
  <div class="max-w-[1440px] px-[10px] md:p-0 mx-auto relative" id="reviews">
    <p class="h6 text-center text-muted">Testimonials</p>
    <h2 class="h1 text-center text-black">What our customers say</h2>
    <swiper
      class="mySwiper py-10"
      :slides-per-view="1"
      :space-between="20"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      :pagination="{ clickable: true }"
      loop
      :modules="modules"
    >
      <swiper-slide
        v-for="(item, index) in customerReview"
        :key="index"
        class="text-center py-10 select-none"
      >
        <div class="flex justify-center py-5">
          <svg
            width="52"
            height="52"
            viewBox="0 0 102 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M46.4169 18.1399L46.4169 50.907L26.9101 82.1399H10.0334L25.0471 52.8796H10.0334L10.0334 18.1399L46.4169 18.1399ZM90.5813 18.1399L90.5813 50.907L71.4032 82.1399H54.0882L69.4306 52.8796L54.0882 52.8796L54.0882 18.1399L90.5813 18.1399Z"
              fill="#6731EC"
            />
          </svg>
        </div>
        <p
          class="body-1 mx-auto lg:max-w-[720px] md:max-w-[500px] max-w-[420px] !font-medium text-pretty px-[20px] text-black"
        >
          {{ item.review }}
        </p>
        <p class="text-pretty body-1 !font-bold pt-5 text-muted">
          {{ item.name }}
        </p>
        <p class="text-pretty body-1 text-muted">{{ item.position }}</p>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>
<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const modules = [Navigation, Pagination, Autoplay];

const customerReview = [
  {
    name: "Binod Bista",
    position: "Co-Owner at Asado Food and Entertainment Pvt. Ltd",
    review:
      "Nepvent RMS revolutionized our restaurant operations with its comprehensive features and user-friendly interface. It’s boosted our efficiency and customer satisfaction, making it indispensable for any restaurant. The support team is also exceptional.",
  },
  {
    name: "Alex P.",
    position: "Founder of HealthTrack Apps",
    review:
      "Collaborating with Detech Solution on the development of our Dami Experience website and mobile app has been an outstanding experience. They understood our vision to create a comprehensive platform that brings together users and services seamlessly. The final product exceeded our expectations with its intuitive design, smooth performance, and engaging user interface. Their team was professional, communicative, and committed to delivering a top-notch solution that has already garnered positive feedback from our users. We couldn’t have asked for a better partner!",
  },
  {
    name: "Sajana Gautam Owner",
    position: "CEO of Travellers Himalaya",
    review:"Working with Detech Solution to develop our travel website was an absolute pleasure. They understood our vision perfectly and delivered a sleek, user-friendly platform that transformed how our customers book their travel plans. The integration of advanced search filters has significantly improved the user experience, resulting in a noticeable increase in bookings. Their team's expertise, responsiveness, and dedication to excellence made the entire process smooth and stress-free. We couldn’t be happier with the results!"
  }
];
</script>
<style lang="scss" scoped>
.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
  color: theme("colors.white");
  background: #6731ec;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  @media (max-width: 552px) {
    top: 140px;
  }
  &:hover {
    transform: scale(1.1);
    background: hsl(257, 83%, 56%, 0.8);
    transition: transform 0.2s ease;
  }
  &::after {
    font-size: 18px;
  }
}

</style>
