<template>
  <div class="max-w-[1440px] mx-auto text-center px-[10px]">
    <h2
      class="text-center text-black leading-[1] relative after:content-[''] after:absolute after:h-[2px] after:w-[75%] after:bottom-[16px] pb-[2rem] after:bg-primary after:left-[12.5%] w-fit mx-auto my-3"
    >
      Work With Us
    </h2>
    <p class="md:text-[20px] text-[16px] max-w-[820px] mx-auto py-4 text-gray-500" v-scroll-reveal data-reveal="left">
      Driven by innovation and excellence, we foster a collaborative, inclusive environment where every team member thrives. Explore our job openings and find the perfect opportunity to grow your career with us.
    </p>
    <div class="pt-4" v-scroll-reveal data-reveal>
      <router-link to="career" class="primary-btn px-3 py-3"
        >Open Position</router-link
      >
    </div>
  </div>
</template>
