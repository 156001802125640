<template>
  <HeroSection class="py-16"/>

  <div class="bg-white py-16">
  <Services class="px-[10px] p-[2.5rem]" />
  </div>

  <div class="py-16">
  <ourWork />
  </div>

  <div class="bg-white py-16">
  <testomonial />
  </div>

  <div class="py-16">
  <faqs />
  </div>

  <div class="py-16">
    <join-us />
  </div>
</template>

<script setup lang="ts">
import Services from "../components/Services.vue";
import HeroSection from "../components/HeroSection.vue";
import testomonial from "../components/Testomonial.vue";
import faqs from "../components/Faqs.vue";
import joinUs from "../components/JoinUs.vue";
import ourWork from "../components/OurWork.vue";

import { useHead } from '@unhead/vue' 

useHead({
  title: 'Detech Solution | Collaborative partners, not projects.',
  meta: [
    {
      name: 'description',
      content: 'Detech Solution offers innovative tech solutions, focusing on collaboration to create lasting partnerships and deliver exceptional results.',
    },
  ],
  link: [
    {
      rel: 'canonical',
      href: 'https://detech.com.np/',
    },
  ]
})

</script>
